//==============================================================================
// Container with Header
//
// Container module with optionally linked header title and header text
//==============================================================================
import * as React from 'react';
import classnames from 'classnames';

import { RichTextComponent } from '@msdyn365-commerce/core';
import { Heading } from '@msdyn365-commerce-modules/utilities';

import { IContainerWithHeaderData } from './container-with-header.data';
import {
    IContainerWithHeaderConfig,
    IContainerWithHeaderProps,
    ILinkData,
    ILinksData,
    ITitleData
} from './container-with-header.props.autogenerated';

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * ContainerWithHeader component
 * @extends {React.PureComponent<IContainerWithHeaderProps<IContainerWithHeaderData>>}
 */
//==============================================================================
class ContainerWithHeader extends React.PureComponent<IContainerWithHeaderProps<IContainerWithHeaderData>> {

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element {
        const { config, slots } = this.props;
        const hasSlots = slots?.contentBlock?.length;
        const hasLinks = config.links?.length;

        return (
            <div className={classnames('container-with-header', config.className)}>
                {config && this._renderHeader(config)}
                {!!hasSlots && this._renderSlotItems(slots.contentBlock)}
                {!!hasLinks && this._renderLinks(config.links!)}
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================

    //------------------------------------------------------
    //------------------------------------------------------
    private _renderHeader(config: IContainerWithHeaderConfig): JSX.Element {
        return (
            <div className='container-with-header__header'>
                {config.title?.text && this._renderLinkedTitle(config)}
                {config.text && <RichTextComponent text={config.text} className='container-with-header__text' />}
            </div>
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    private _renderLinkedTitle(config: IContainerWithHeaderConfig): JSX.Element {
        const { link, title } = config;

        if (link?.linkUrl.destinationUrl) {
            return (
                <a href={link.linkUrl.destinationUrl} aria-label={link.ariaLabel}>
                    {this._renderTitle(title!)}
                </a>
            );
        } else {
            return this._renderTitle(title!);
        }
    }

    //------------------------------------------------------
    //------------------------------------------------------
    private _renderTitle(title: ITitleData): JSX.Element {
        return (
            <Heading
                className='container-with-header__title'
                headingTag={title.tag}
                text={title.text}
            />
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    private _renderSlotItems(items: React.ReactNode[]): JSX.Element {
        return (
            <div className='container-with-header__slots'>
                {items.map((slot: React.ReactNode, index: number) => (
                    <React.Fragment key={index}>
                        {slot}
                    </React.Fragment>
                ))}
            </div>
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    private _renderLinks(links: ILinksData[]): JSX.Element {
        return (
            <div className='container-with-header__links'>
                {links.map((link: ILinkData, index: number) => (
                    <a
                        key={index}
                        className={index === 0 ? 'msc-cta__primary' : 'msc-cta__secondary'}
                        href={link.linkUrl?.destinationUrl}
                        title={link.linkText}
                        aria-label={link.ariaLabel}
                        target={link.openInNewTab ? '_blank' : undefined}
                        rel='noreferrer'
                        role='button'
                    >
                        {link.linkText}
                    </a>
                ))}
            </div>
        );
    }
}

export default ContainerWithHeader;
